import type { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import type { MultiselectProps } from '@amzn/awsui-components-react/polaris/multiselect';
import { Density, Mode } from '@amzn/awsui-global-styles';

import { TablePreferenceOptions } from '@/common/models';
import { EventType, InterviewerRole, LeadershipPrinciple, OrgConfigDefinitionType } from '@/graphql/types';
import { ElevatePageRoute } from '@/utilities/page-definitions';

export const DEFAULT_CONTENT_DENSITY = Density.Comfortable;
export const DEFAULT_VISUAL_MODE = Mode.Light;

export const SUPPORT_ITEMS_URLS = {
  elevateWiki: 'https://w.amazon.com/bin/view/AWS/EnterpriseSupport/ElevateHire/',
  slackChannel: 'https://amzn-aws.slack.com/archives/C01UKFNH21Y',
  contactUs: 'https://t.corp.amazon.com/create/templates/847346d4-5e4e-48f8-8432-56ce906a2004',
};

export const PHOTO_URL = 'https://badgephotos.corp.amazon.com/?uid=';
export const PHONETOOL_URL = 'https://phonetool.amazon.com/users/';

export const ELEVATE_TITLE =
  import.meta.env.MODE === 'development'
    ? 'Elevate (local)'
    : import.meta.env.REACT_APP_BUILD_ENV === 'prod'
      ? 'Elevate'
      : import.meta.env.REACT_APP_BUILD_ENV === 'staging'
        ? 'Elevate (Staging)'
        : 'Elevate (Dev)';

export const HIRE_ID_REGEX = /(?<=\/)\d{6,8}$/;

export const APPSYNC_GRAPHQL_GET_LIMIT = 1000;

export const CACHE_KEY_AUTH_START_PATH = 'elevate-auth-start-path';

export const auth_actions = {
  // Organizations
  VIEW_ORGANIZATIONS: 'VIEW_ORGANIZATIONS',
  CREATE_ORGANIZATION: 'CREATE_ORGANIZATION',
  EDIT_ORGANIZATION: 'EDIT_ORGANIZATION',

  // Candidates
  VIEW_CANDIDATE_NAME: 'VIEW_CANDIDATE_NAME',

  // Comments
  CREATE_EVENT_COMMENT: 'CREATE_EVENT_COMMENT',
  DELETE_COMMENT: 'DELETE_COMMENT',
  VIEW_EVENT_COMMENT: 'VIEW_EVENT_COMMENT',
  VIEW_FEEDBACK: 'VIEW_FEEDBACK',

  LEAD_EVENT: 'LEAD_EVENT',
  // Phone Screens
  CREATE_PHONE_SCREEN: 'CREATE_PHONE_SCREEN',
  EDIT_ANY_ORG_PHONE_SCREEN: 'EDIT_ANY_ORG_PHONE_SCREEN',
  EDIT_SCHEDULED_PHONE_SCREEN: 'EDIT_SCHEDULED_PHONE_SCREEN',
  VIEW_PHONE_SCREEN_SHADOW_OPPORTUNITIES: 'VIEW_PHONE_SCREEN_SHADOW_OPPORTUNITIES',

  // Loops
  ENTER_DEBRIEF_RESULTS: 'ENTER_DEBRIEF_RESULTS',
  MANAGE_INTERVIEWERS: 'MANAGE_INTERVIEWERS',
  CREATE_LOOP_EVENT: 'CREATE_LOOP_EVENT',
  EDIT_ANY_ORG_LOOP: 'EDIT_ANY_ORG_LOOP',
  FIND_AVAILABLE_INTERVIEWER: 'FIND_AVAILABLE_INTERVIEWER',
  EXPERIMENTAL_FEATURES: 'EXPERIMENTAL_FEATURES',
  EDIT_SCHEDULED_LOOP: 'EDIT_SCHEDULED_LOOP',

  // BR
  MANAGE_BAR_RAISERS: 'MANAGE_BAR_RAISERS',
  VIEW_BAR_RAISERS: 'VIEW_BAR_RAISERS',

  // Managers
  VIEW_TEAM_MANAGER_VIEW: 'VIEW_TEAM_MANAGER_VIEW',

  // Interviewer Management
  INTERVIEWER_MANAGEMENT: 'INTERVIEWER_MANAGEMENT',
  LOOKUP_INTERVIEWERS: 'LOOKUP_INTERVIEWERS',
} as const;

export const MIN_AVAILABILITY_DAYS_OUT = 3;

/*
  This is the total used for other internal tools (SIM/CMC).
  DynamoDB enforces a string limit of 400KB which gets you
  400000 latin-based chars and 100000 C/J/K or Emoji chars,
  so if we need to bump this up we can.
*/
export const MAX_CHAR_DEFAULT_LIMIT = 59_000;

export const MARKDOWN_SYNTAX_GUIDE = 'https://docs.github.com/en/get-started/writing-on-github';

export const MAX_CHAR_EVENT_CONFIG_NAME = 50;

export const MAX_CHAR_PARTICIPANT_NAME = 50;

export const MAX_CHAR_CALIBRATION_NAME = 50;

export const MAX_CHAR_PARTICIPANT_DESC = 150;

export const MAX_CHAR_CALIBRATION_DESC = 150;

export const MAX_CHAR_EVENT_CONFIG_DESC = 150;

export const PHONESCREEN_DEFAULT_DURATION = 60;

export const LOOP_DEFAULT_DURATION = 4 * 60 + 30;

export const MAX_SHARED_PARTICIPANTS = 20;

export const MAX_COLLABORATIONS = 15;

export const EVENT_CONFIG_NO_CHANGE_INFO =
  'Unable to change the config for a scheduled event. This is due to potentially having different Interviewer roles and requirements associated with different events. To change the config, please cancel this event and create a new one.';

export const DEFAULT_MAX_PARTICIPANT_SHADOWS = 2;

export const DEFAULT_BREAK_DURATION_MINUTES = 30;

export const LEAD_ROLES: Set<InterviewerRole | `${InterviewerRole}`> = new Set([
  InterviewerRole.LEAD,
  InterviewerRole.INDEPENDENT,
]);

export const SHADOW_ROLES: Set<InterviewerRole | `${InterviewerRole}`> = new Set([
  InterviewerRole.SHADOW,
  InterviewerRole.REVERSE_SHADOW,
]);

export const EVENT_CONFIG_TYPES = {
  [EventType.LOOP]: OrgConfigDefinitionType.LOOP_CONFIG,
  [EventType.PHONE_SCREEN]: OrgConfigDefinitionType.PHONESCREEN_CONFIG,
} as const;

export const PARTICIPANT_TYPES = {
  [EventType.LOOP]: OrgConfigDefinitionType.LOOP_PARTICIPANT,
  [EventType.PHONE_SCREEN]: OrgConfigDefinitionType.PHONESCREEN_PARTICIPANT,
} as const;

export const CALIBRATION_TYPES = {
  [EventType.LOOP]: OrgConfigDefinitionType.LOOP_CALIBRATION,
  [EventType.PHONE_SCREEN]: OrgConfigDefinitionType.PHONESCREEN_CALIBRATION,
} as const;

export const QUIP_URL_PREFIX = 'https://quip-amazon.com/';

export const STATIC_ROUTES = {
  ACCESS_DENIED: '/access-denied',
  NOT_FOUND: '/not-found',
} satisfies Record<string, ElevatePageRoute>;

export const TOP_NAV_ELEMENT_ID = 'ElevateTopNavigation';

export const DEFAULT_TABLE_PREFERENCES = {
  contentDensity: 'compact',
  contentDisplay: undefined,
  pageSize: 30,
  stickyColumns: { first: 0, last: 0 },
  stripedRows: false,
  wrapLines: false,
} as const satisfies CollectionPreferencesProps.Preferences;

export const DEFAULT_TABLE_PREFERENCE_OPTIONS = {
  pageSizes: [10, 30, 50, 70, 100],
  stickyColumnsFirst: [0, 1, 2, 3],
  stickyColumnsLast: [0, 1],
} as const satisfies TablePreferenceOptions;

export const amazonLeadershipPrinciples: MultiselectProps.Options = [
  {
    label: 'Customer Obsession',
    value: LeadershipPrinciple.CUSTOMER_OBSESSION,
  },
  {
    label: 'Ownership',
    value: LeadershipPrinciple.OWNERSHIP,
  },
  {
    label: 'Invent and Simplify',
    value: LeadershipPrinciple.INVENT_AND_SIMPLIFY,
  },
  {
    label: 'Are Right A Lot',
    value: LeadershipPrinciple.ARE_RIGHT_A_LOT,
  },
  {
    label: 'Learn and Be Curious',
    value: LeadershipPrinciple.LEARN_AND_BE_CURIOUS,
  },
  {
    label: 'Hire and Develop the Best',
    value: LeadershipPrinciple.HIRE_AND_DEVELOP_THE_BEST,
  },
  {
    label: 'Insist on the Highest Standards',
    value: LeadershipPrinciple.INSIST_ON_THE_HIGHEST_STANDARDS,
  },
  {
    label: 'Think Big',
    value: LeadershipPrinciple.THINK_BIG,
  },
  {
    label: 'Bias for Action',
    value: LeadershipPrinciple.BIAS_FOR_ACTION,
  },
  {
    label: 'Frugality',
    value: LeadershipPrinciple.FRUGALITY,
  },
  {
    label: 'Earn Trust',
    value: LeadershipPrinciple.EARN_TRUST,
  },
  {
    label: 'Dive Deep',
    value: LeadershipPrinciple.DIVE_DEEP,
  },
  {
    label: 'Have Backbone; Disagree and Commit',
    value: LeadershipPrinciple.HAVE_BACKBONE_DISAGREE_AND_COMMIT,
  },
  {
    label: 'Deliver Results',
    value: LeadershipPrinciple.DELIVER_RESULTS,
  },
  {
    label: 'Strive to be Earth\'s Best Employer',
    value: LeadershipPrinciple.STRIVE_TO_BE_EARTHS_BEST_EMPLOYER,
  },
  {
    label: 'Success and Scale Bring Broad Responsibility',
    value: LeadershipPrinciple.SUCCESS_AND_SCALE_BRING_BROAD_RESPONSIBILITY,
  },
];
